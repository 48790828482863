<template>
    <div class="oxy-snap-page">
        <div class="video-box">
            <video
                controls
                autoplay
                width="100%"
                height="100%"
                object-fit="fill"
                :src="videoList[selectIndex].url"
                playsinline
                webkit-playsinline
                :poster="`${ossHostPond}img/oxysnap_tutorial_bg.png`"
            >
            </video>
        </div>
        <div class="video-title">
            {{selectIndex+1}}/3 {{videoList[selectIndex].title}}
        </div>
        <div class="btn-list">
            <div class="btn-item" @click="onclickChangeVideo(0)">上一个</div>
            <div class="btn-item" @click="onclickChangeVideo(1)">下一个</div>
        </div>
        <div class="bottom-box">
            <img :src="`${ossHostPond}img/oxysnap_bottom.png`" alt="">
            <!-- <div class="openBtn">查看更多智能设备</div> -->
            <wx-open-launch-weapp
                id="launch-btn"
                username="gh_17df9530faf3"
                path='main/pages/activity/categoryReport/index' 
                class="openBtn"
            >
                <script type="text/wxtag-template">
                    <div style="width:100%;text-align:center;line-height:50px;color:#fff;font-size:20px;">查看更多智能设备</div>
                </script>
            </wx-open-launch-weapp>
        </div>
    </div>
</template>

<script>
export default {
    name: "OxySnap",
    data() {
        const { ossHostPond } = this.$config.base;
        return {
            wxInfo: {},
            ossHostPond,
            // 当前视频
            selectIndex: 0,
            // 视频列表
            videoList:[
                {
                    url: `${ossHostPond}video/oxysnap_tutorial.mp4`,
                    title: '测量及注意事项'
                },
                {
                    url: `${ossHostPond}video/oxysnap_oxygen_calibration.mp4`,
                    title: '饱和氧校准流程'
                },
                {
                    url: `${ossHostPond}video/oxysnap_zoxygen_calibration.mp4`,
                    title: '零氧校准流程'
                },
            ],
        };
    },
    created() {
        const vm = this;
        vm.weixinInit()
    },
    methods: {
        // 获取微信配置
        weixinInit() {
            const vm = this;
            vm.$http({
                type: "get",
                url: `https://m.yudada.com/jssdk/configuration?url=${
                    location.href
                }`
            }).then(res => {
                const { appId, nonceStr, signature, timestamp } = res.data;
                vm.wxInfo = res.data;
                window.wx.config({
                    debug: false,
                    appId,
                    timestamp,
                    nonceStr,
                    signature,
                    jsApiList: ['wx-open-launch-weapp'],
                    openTagList: ['wx-open-launch-weapp'],
                });
            });
        },

        // 切换视频
        onclickChangeVideo(type) {
            const vm = this;
            if (type == 0) {
                if (!vm.selectIndex <= 0) {
                    vm.selectIndex--;
                } 
            }
            if (type == 1) {
                if (vm.selectIndex < vm.videoList.length - 1) {
                    vm.selectIndex++;
                } 
            }

        }
    }
};
</script>

<style lang="less" scoped>
@import "@/less/base.less";
.oxy-snap-page {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: @bgColor6;
    // 滚动条
    overflow-y: auto;
    .video-box {
        height: 3.9rem;
        width: 2.2rem;
        margin: 0.2rem auto 0 auto;
        text-align: center;
    }
    .video-title {
        color: @blank;
        font-size: 0.2rem;
        text-align: center;
        margin-top: 0.1rem;
    }
    .btn-list {
        display: flex;
        justify-content: space-around;
        margin-top: 0.2rem;
        padding-bottom: 2rem;
        .btn-item {
            width: 1.2rem;
            height: 0.4rem;
            line-height: 0.4rem;
            text-align: center;
            background-color: @blank;
            color: @bgColor6;
            font-size: 0.2rem;
            border-radius: 0.2rem;
        }
    }
    .bottom-box {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1.8rem;
        img {
            width: 100%;
            height: 100%;
        }
        .openBtn {
            position: absolute;
            width: 2rem;
            height: 0.5rem;
            line-height: 0.5rem;
            bottom: 0.15rem;
            text-align: center;
            background-color: @bgColor7;
            color: @blank;
            font-size: 0.2rem;
            border-radius: 0.25rem;
            left: 50%;
            transform: translateX(-50%);

        }
    }
}
</style>